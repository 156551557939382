import React, { useEffect, useState } from "react";
import { BiChevronDown } from "../../assets/Icons/Icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../state/store";
import {
  courseDataHandler,
  getSessionListByClassIdAndCourseId,
  getStudentCourse,
} from "../../state/actions/courseActions";
import { useLocation } from "react-router";
import { sessionDataHandler } from "@/state/actions/sessionActions";
import Accordion from "react-bootstrap/Accordion";
import { EventEmitter } from "@/utils/helper";
import CommonModal from "@/components/common/Modal";
import { Box } from "@mui/material";
import { MdCheck } from "react-icons/md";
import { FaCheck } from "react-icons/fa";
function Course() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [studentCourse, setStudentCourse] = useState([]);
  const [studentId, setStudentId] = useState();
  const [orignalSession, setOrginalSession] = useState([]);
  const [reviseSession, setreviseSession] = useState([]);
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  console.log("state", state);

  const userAdmin = useSelector((store) => store?.auth?.user);

  EventEmitter.subscribe("trailUpdate", (res) => {
    openSlideHandler();
  });

  EventEmitter.subscribe("trailStatus", (res) => {
    openSlideHandler();
  });

  useEffect(() => {
    localStorage.setItem("studentId", userAdmin?.id);
    // getDetails(userAdmin?.id);
    setStudentId(userAdmin?.id);
  }, [userAdmin]);

  const getCourseDetails = async (classId, courseId) => {
    const response = await getSessionListByClassIdAndCourseId(
      classId,
      courseId
    );
    if (response && response.status === true) {
      setOrginalSession(response.original);
      setreviseSession(response.revise);
    } else {
      setOrginalSession([]);
      setreviseSession([]);
    }
  };

  useEffect(() => {
    if (state.courseId && state.classId) {
      getCourseDetails(state.classId, state.courseId);
    }
  }, [state.courseId, state.classId]);

  const openSlideHandler = () => {
    const trailOpenStatus = localStorage.getItem("trailOpen");
    console.log("trailOpenStatus", trailOpenStatus);
    const trailStatus = localStorage.getItem("trailStatus");
    console.log("trailStatus", trailStatus);
    if (trailOpenStatus) {
      console.log("inside");

      if (trailStatus === "ASKTRAIL" || "NOTAGREE") {
        setIsModalOpen({
          open: true,
          currentComponent: "slide1",
        });
      }

      if (trailStatus === "PENDING") {
        setIsModalOpen({
          open: true,
          currentComponent: "slide2",
        });
      }

      // if(trailStatus === "NO"){
      //   setIsModalOpen({
      //     open: true,
      //     currentComponent: "slide2",
      //   });
      // }

      if (trailStatus === "AGREE") {
        setIsModalOpen({
          open: true,
          currentComponent: "slide3",
        });
      }
    }
  };

  console.log("orignalSession", orignalSession)
  return (
    <>
      <div className="font-archia min-h-[calc(100vh-274px)] max-[992px]:min-h-[calc(100vh-179px)]">
        <div className="flex gap-y-10 flex-col items-center px-[85px] my-[70px] max-[480px]:px-5">
          {/* {studentCourse?.map((title, i) => { */}
          {/* return ( */}
          <div className="w-100">
            <Accordion
              defaultActiveKey=""
              className="w-100 cursor-pointer courses rounded-[30px] flex items-center border-[3px] max-[1100px]:gap-x-2 justify-between border-[#D3D3D3]"
            >
              <Accordion.Item eventKey="0" className="w-100 ovasd">
                <Accordion.Header className="w-100 d-flex justify-content-between">
                  <span className="default whitespace-normal flex-shrink-0 font-semib-24 max-[800px]:w-[80%]">
                    {state?.data.courseName}
                  </span>
                  <span className="bg-[--primary] rounded-full px-3 py-2 text-[25px] text-white flex-shrink-0 max-[800px]:px-[6px] max-[800px]:py-[2px] max-[800px]:text-[22px] max-[600px]:text-[20px] absolute right-5 z-50">
                    <BiChevronDown />
                  </span>
                </Accordion.Header>
                <Accordion.Body className="p-3">
                  <div className="max-w-[830px] flex m-auto gap-4">
                    <div className="flex flex-col gap-3">
                      {orignalSession.length >= 0 &&
                        orignalSession.map((res) => {
                          return (
                            <div className="flex flex-row gap-3 items-center">
                              <div
                                class="w-[400px] max-[1000px]:w-[300px] max-[800px]:w-[100%] flex items-center px-3 max-[600px]:px-2 border border-gray-200 rounded-xl dark:border-gray-700"
                                onClick={() => {
                                  if (!res.isCompleted) {
                                    const trailStatus = localStorage.getItem("trailStatus");
                                    if (trailStatus === "TRAILAGREE") {
                                      dispatch(courseDataHandler(state?.data));
                                      dispatch(sessionDataHandler(res));
                                      localStorage.setItem("sessionId", res.id);
                                      localStorage.setItem(
                                        "classId",
                                        res.classID
                                      );
                                      localStorage.setItem(
                                        "courseId",
                                        res.courseID
                                      );
                                      localStorage.setItem(
                                        "pptCondition",
                                        "true"
                                      );
                                      localStorage.setItem(
                                        "audioCondition",
                                        "true"
                                      );
                                      navigate("/questions", {
                                        state: {
                                          sessionId: res.id,
                                          studentId: studentId,
                                          classId: res.classID,
                                          courseId: res.courseID,
                                        },
                                      });
                                    } else {
                                      EventEmitter.dispatch("trailUpdate", true);
                                      openSlideHandler();
                                      return;
                                    }
                                  }
                                }
                                }
                              >
                                <label
                                  for="bordered-checkbox-1"
                                  class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-700 max-[600px]:text-xs"
                                >
                                  {res.sessionName}
                                </label>
                                {!res.isCompleted ? (
                                  <input
                                    id="bordered-checkbox-1"
                                    type="checkbox"
                                    defaultChecked={false}
                                    disabled={true}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    name="bordered-checkbox"
                                    className="w-4 h-4 text-[--primary] bg-gray-100 border-gray-300 rounded focus:ring-[--primary] dark:focus:ring-[--primary] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                ) : (

                                  <div className="text-green-700 font-bold" onClick={(e) => {
                                    e.stopPropagation()
                                  }}>
                                    <FaCheck size={20} />{" "}
                                  </div>
                                )}

                              </div>
                              {res.match && (
                                <div className="bg-[#ff751f] px-3 py-1 text-white rounded-md w-fit h-fit">
                                  {res.match.gainMarks}/{res.match.totalMark}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                    <div className="flex flex-col gap-3">
                      {reviseSession.length >= 0 &&
                        reviseSession.map((res) => {
                          return (
                            <div className="flex flex-row gap-3 items-center">

                              <div
                                class="w-[400px] max-[1000px]:w-[300px] max-[800px]:w-[100%] flex items-center px-3 max-[600px]:px-2 border border-gray-200 rounded-xl dark:border-gray-700"
                                onClick={() => {
                                  if (!res.isCompleted) {
                                    const trailStatus = localStorage.getItem("trailStatus");
                                    if (trailStatus === "TRAILAGREE") {
                                      dispatch(courseDataHandler(state?.data));
                                      dispatch(sessionDataHandler(res));
                                      localStorage.setItem("sessionId", res.id);
                                      localStorage.setItem(
                                        "classId",
                                        res.classID
                                      );
                                      localStorage.setItem(
                                        "courseId",
                                        res.courseID
                                      );
                                      localStorage.setItem(
                                        "pptCondition",
                                        "true"
                                      );
                                      localStorage.setItem(
                                        "audioCondition",
                                        "true"
                                      );
                                      navigate("/questions", {
                                        state: {
                                          sessionId: res.id,
                                          studentId: studentId,
                                          classId: res.classID,
                                          courseId: res.courseID,
                                        },
                                      });
                                    } else {
                                      EventEmitter.dispatch("trailUpdate", true);
                                      openSlideHandler();
                                      return;
                                    }
                                  }
                                }
                                }
                              >
                                <label
                                  for="bordered-checkbox-1"
                                  class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-700 max-[600px]:text-xs"
                                >
                                  {res.sessionName}
                                </label>
                                {res.isCompleted ? (
                                  <div className="text-green-700 font-bold">
                                    <FaCheck size={20} />{" "}
                                  </div>
                                ) : (
                                  <input
                                    id="bordered-checkbox-1"
                                    type="checkbox"
                                    defaultChecked={false}
                                    disabled={true}
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    name="bordered-checkbox"
                                    className="w-4 h-4 text-[--primary] bg-gray-100 border-gray-300 rounded focus:ring-[--primary] dark:focus:ring-[--primary] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  />
                                )}

                              </div>
                              {res.match && (
                                <div className="bg-[#ff751f] px-3 py-1 text-white rounded-md w-fit h-fit">
                                  {res.match.gainMarks}/{res.match.totalMark}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
        <CommonModal
          modalOpen={isModalOpen}
          handleClose={() =>
            setIsModalOpen({ open: false, currentComponent: "" })
          }
        />
      </div>
    </>
  );
}

export default Course;
